import type { StoryblokContent } from '../storyblok'
import type Storyblok from '../vendors/storyblok'
import type { EdgeStyles, Link, LongTitle, ShortTitle } from './shared'
import { normalizeRichtext } from '~/utils/storyblok/richtext'

export interface TextBlockV2 extends StoryblokContent {
  component: 'text-block-v2'
  backgroundColor: string
  fontAndCtaColor: string
  title: (ShortTitle | LongTitle)[]
  is_main_page_heading: boolean
  isHigh: boolean
  contentPosition: 'isCentered' | 'isOnlyTitleLeft' | 'isContentLeft'
  text: Storyblok.Richtext
  label: string
  link: Link
  ctaLabel: string
  ctaLink: Link
  email: Link
  edgeStyle: EdgeStyles
}

export function normalizeTextBlockV2(blok: TextBlockV2) {
  return {
    backgroundColor: blok?.backgroundColor,
    fontAndCtaColor: blok?.fontAndCtaColor,
    shortTitle: blok?.title?.[0]?.component === 'shortTitle' ? blok.title[0].text : undefined,
    longTitle: blok?.title?.[0]?.component === 'longTitle' ? blok.title[0].text : undefined,
    isMainPageHeading: blok?.is_main_page_heading || false,
    isHigh: blok?.isHigh,
    isCentered: blok?.contentPosition === 'isCentered',
    isOnlyTitleLeft: blok?.contentPosition === 'isOnlyTitleLeft',
    isContentLeft: blok?.contentPosition === 'isContentLeft',
    text: normalizeRichtext(blok?.text),
    label: blok?.label,
    link: blok?.link?.cached_url,
    ctaLabel: blok?.ctaLabel,
    ctaLink: blok?.ctaLink?.cached_url,
    email: blok?.ctaLink?.email,
    edgeStyle: blok?.edgeStyle ?? 'none',
  }
}
